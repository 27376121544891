/**
*   PADDING
*/
@for $i from 1 through 20 {
    .b-py-#{5 * $i}{
        padding-top: 5px * $i;
        padding-bottom: 5px * $i;
    }
}
@for $i from 1 through 20 {
    .b-px-#{5 * $i}{
        padding-left: 5px * $i;
        padding-right: 5px * $i;
    }
}
@for $i from 1 through 26 {
    .b-pt-#{5 * $i} {
        padding-top: 5px * $i;
    }
}
@for $i from 1 through 26 {
    .b-pb-#{5 * $i} {
        padding-bottom: 5px * $i;
    }
}
@for $i from 1 through 10 {
    .b-pl-#{5 * $i} {
        padding-left: 5px * $i;
    }
}
@for $i from 1 through 10 {
    .b-pr-#{5 * $i} {
        padding-right: 5px * $i;
    }
}

/**
*   MARGIN
*/
@for $i from 1 through 26 {
    .b-mt-#{5 * $i} {
        margin-top: 5px * $i;
    }
}
@for $i from 1 through 26 {
    .b-mb-#{5 * $i} {
        margin-bottom: 5px * $i;
    }
}
@for $i from 1 through 10 {
    .b-ml-#{5 * $i} {
        margin-left: 5px * $i;
    }
}
@for $i from 1 through 10 {
    .b-mr-#{5 * $i} {
        margin-right: 5px * $i;
    }
}