@each $name, $color in $colors{
    .color-#{$name}, .text-#{$name}{
        color: $color;
    }
}
.text-rainbow{
    background: linear-gradient(-45deg, $color-blue, $color-indigo, $color-purple, $color-pink, $color-red, $color-orange, $color-yellow, $color-green, $color-teal, $color-cyan);
    background: -webkit-linear-gradient(-45deg, $color-blue, $color-indigo, $color-purple, $color-pink, $color-red, $color-orange, $color-yellow, $color-green, $color-teal, $color-cyan); 
    background: -moz-linear-gradient(-45deg, $color-blue, $color-indigo, $color-purple, $color-pink, $color-red, $color-orange, $color-yellow, $color-green, $color-teal, $color-cyan); 
    background: -ms-linear-gradient(-45deg, $color-blue, $color-indigo, $color-purple, $color-pink, $color-red, $color-orange, $color-yellow, $color-green, $color-teal, $color-cyan);
    background: -o-linear-gradient(-45deg, $color-blue, $color-indigo, $color-purple, $color-pink, $color-red, $color-orange, $color-yellow, $color-green, $color-teal, $color-cyan);

    text-shadow: none;
    background-size: 1000% 1000%;

    @include animation(gradient, 15s, ease, infinite);
    @include background_clip(text);
    @include text_fill_color(transparent);
}