#b-rgpd-banner{
    display: none; 
    position: fixed; 
    bottom: 0; 
    width: 100%; 
    z-index: $zindex+1;
    padding: 5px;
}
#b-rgpd-banner > :first-child{
    right: 0;
}



/**
*   THEMES
*/ 
@import './scss/themes/dark';