$font-size  : 1rem;
$font-family: Arial, Helvetica, sans-serif;

/**
*   HTML | BODY
*/
html, body{
    position: relative;
    height: 100%;
    width: 100%;
    font-size: $font-size;
    font-family: $font-family;
}

#header > .container{
    margin-bottom: -115px;
    position: relative;
    z-index: 21;
}

