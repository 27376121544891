footer{
    height: $footer_height;
    line-height: $footer_height;
    z-index: $zindex;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
}
footer .copyright{
    padding:  0 15px 0 15px;
}