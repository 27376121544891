@each $name, $color in $backgrounds{
    .bg-#{$name}{
        background-color: $color;
    }
}
@each $name, $color in $backgrounds_image{
    .bg-#{$name}{
        background: $color;
    }
}

%bg-granule{background: $bg-granule;}
.bg-alpha-dark,
%bg-dark{background: $bg-dark;}
%bg-dark-fixed{background: $bg-dark-fixed;}
%bg-middle-dark{background: $bg-middle-dark;}
%bg-triangle-dark{background: $bg-triangle-dark;}
%bg-triangle{background: $bg-triangle;}
%bg-triangle-light{background: $bg-triangle-light;}
%bg-iphone-light{background: $bg-iphone-light;}
%bg-light{background: $bg-light;}
%bg-wave-light{background: $bg-wave-light;}


.bg-rainbow-animate,
%bg-rainbow-animate{
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size   : 400% 400%;
    animation         : gradient 15s ease infinite;
}
.bg-gradient-dark,
%bg-gradient-dark{
    background-color: #000000;
    background-image: linear-gradient(147deg, #000000 0%, #434343 74%);
}

.bg-sharp-blue{
    background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
    color:white;
}
.bg-darty-beauty{
    background-image: linear-gradient(to top, #6a85b6 0%, #bac8e0 100%);
    color:white;
}
.bg-teen-notebook{
    background-image: linear-gradient(to top, #9795f0 0%, #fbc8d4 100%);
}
.bg-spicky-naga{
    background-image: linear-gradient(to top, #505285 0%, #585e92 12%, #65689f 25%, #7474b0 37%, #7e7ebb 50%, #8389c7 62%, #9795d4 75%, #a2a1dc 87%, #b5aee4 100%);
}
.bg-alive{
    background: #CB356B;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #BD3F32, #CB356B);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #BD3F32, #CB356B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bg-dark-skies{
    background: #4B79A1;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #283E51, #4B79A1);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #283E51, #4B79A1); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bg-anamnisar{
    background: #9796f0;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #fbc7d4, #9796f0);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #fbc7d4, #9796f0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bg-kashmir{
    background: #614385;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #516395, #614385);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #516395, #614385); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bg-multicolor{
    background-image: -webkit-linear-gradient(to right, $bg-red, $bg-pink, $bg-purple, $bg-indigo, $bg-blue);
    background-image:    -moz-linear-gradient(to right, $bg-red, $bg-pink, $bg-purple, $bg-indigo, $bg-blue);
    background-image:     -ms-linear-gradient(to right, $bg-red, $bg-pink, $bg-purple, $bg-indigo, $bg-blue);
    background-image:      -o-linear-gradient(to right, $bg-red, $bg-pink, $bg-purple, $bg-indigo, $bg-blue);
    background-image:         linear-gradient(to right, $bg-red, $bg-pink, $bg-purple, $bg-indigo, $bg-blue);
}
.bg-rainbow{
    background-image: -webkit-linear-gradient(to right, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:    -moz-linear-gradient(to right, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:     -ms-linear-gradient(to right, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:      -o-linear-gradient(to right, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:         linear-gradient(to right, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
}
.bg-rainbow-top{
    background-image: -webkit-linear-gradient(to top, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:    -moz-linear-gradient(to top, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:     -ms-linear-gradient(to top, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:      -o-linear-gradient(to top, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:         linear-gradient(to top, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
}
.bg-rainbow-bottom{
    background-image: -webkit-linear-gradient(to bottom, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:    -moz-linear-gradient(to bottom, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:     -ms-linear-gradient(to bottom, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:      -o-linear-gradient(to bottom, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
    background-image:         linear-gradient(to bottom, $bg-blue, $bg-indigo, $bg-purple, $bg-pink, $bg-red, $bg-orange, $bg-yellow, $bg-green, $bg-teal, $bg-cyan);
}