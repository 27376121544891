@each $name, $color in $colors{
    .border-left-#{$name}{
        border-left: 3px solid $color;
    }
    .border-right-#{name}{
        border-right: 3px solid $color;
    }
    .border-top-#{name}{
        border-top: 3px solid $color;
    }
    .border-bottom-#{name}{
        border-bottom: 3px solid $color;
    }
    .border-#{name}{
        border: 3px solid $color;
    }
}
