@font-face{
    font-family :   "Gilroy-Bold ☞";
    font-style  :   normal;
    font-weight :   fontweight(bold);
    src         :   local("Gilroy-Bold ☞"), url("../fonts/Gilroy-Bold.woff") format("woff");
}
@font-face{
    font-family :   "Gilroy-Heavy ☞";
    font-style  :   normal;
    font-weight :   fontweight(heavy);
    src         :   local("Gilroy-Heavy ☞"), url("../fonts/Gilroy-Heavy.woff") format("woff");
}
@font-face{
    font-family :   "Gilroy-Light ☞";
    font-style  :   normal;
    font-weight :   fontweight(light);
    src         :   local("Gilroy-Light ☞"), url("../fonts/Gilroy-Light.woff") format("woff");
}
@font-face{
    font-family :   "Gilroy-Medium ☞";
    font-style  :   normal;
    font-weight :   fontweight(medium);
    src         :   local("Gilroy-Medium ☞"), url("../fonts/Gilroy-Medium.woff") format("woff");
}
@font-face {
    font-family :   "Gilroy-Regular ☞";
    font-style  :   normal;
    font-weight :   fontweight(regular);
    src         :   local("Gilroy-Regular ☞"), url("../fonts/Gilroy-Regular.woff") format("woff");
}