.slider-container{
    max-height: 580px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
  
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.5);
     -khtml-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.5);
       -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.5);
        -ms-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.5);
         -o-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.5);
            box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.5);
  }
  .slider-container > .slider .tp-caption{
    font-family:inherit;
    
    -webkit-text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
     -khtml-text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
       -moz-text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
        -ms-text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
         -o-text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  }