
@-webkit-keyframes gradient {
    0%{background-position: 0% 50%;}
    50%{background-position: 100% 50%;}
    100%{background-position: 0% 50%;}
}
@-moz-keyframes gradient {
    0%{background-position: 0% 50%;}
    50%{background-position: 100% 50%;}
    100%{background-position: 0% 50%;}
}
@keyframes gradient {
    0%{background-position: 0% 50%;}
    50%{background-position: 100% 50%;}
    100%{background-position: 0% 50%;}
}

@-webkit-keyframes pulse {to {box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);}}
@-moz-keyframes pulse {to {box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);}}
@keyframes pulse {to {box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);}}

@-webkit-keyframes animateGlow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes animateGlow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes animateGlow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@-webkit-keyframes bounce {
    0%, 100% {-webkit-transform: translateY(0);}
    50% {-webkit-transform: translateY(-20px);}
}
@-moz-keyframes bounce{
    0%, 100% {transform: translateY(0);}
    50% {transform: translateY(-20px);}
}
@keyframes bounce {
    0%, 100% {transform: translateY(0);}
    50% {transform: translateY(-20px);}
}

@-webkit-keyframes spin {
    0%{@include transform_rotate(0deg);}
    100%{@include transform_rotate(360deg);}
}
@-moz-keyframes spin {
    0%{@include transform_rotate(0deg);}
    100%{@include transform_rotate(360deg);}
}
@keyframes spin {
    0%{@include transform_rotate(0deg);}
    100%{@include transform_rotate(360deg);}
}