@mixin shadow($shadow){
    -webkit-box-shadow: $shadow;
       -moz-box-shadow: $shadow;
        -ms-box-shadow: $shadow;
         -o-box-shadow: $shadow;
            box-shadow: $shadow;
}
@mixin boxShadow($top, $left, $blur, $spread, $color, $inset : false){
    @if $inset{
                box-shadow: inset $top $left $blur $spread $color;
             -o-box-shadow: inset $top $left $blur $spread $color;
            -ms-box-shadow: inset $top $left $blur $spread $color;
           -moz-box-shadow: inset $top $left $blur $spread $color;
        -webkit-box-shadow: inset $top $left $blur $spread $color;
    } 
    @else{
                box-shadow: $top $left $blur $spread $color;
             -o-box-shadow: $top $left $blur $spread $color;
            -ms-box-shadow: $top $left $blur $spread $color;
           -moz-box-shadow: $top $left $blur $spread $color;
        -webkit-box-shadow: $top $left $blur $spread $color;
    }
}
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset{
                 box-shadow: inset $top $left $blur $color;
              -o-box-shadow: inset $top $left $blur $color;
             -ms-box-shadow: inset $top $left $blur $color;
            -moz-box-shadow: inset $top $left $blur $color;
         -webkit-box-shadow: inset $top $left $blur $color;
    } 
    @else{
                box-shadow: $top $left $blur $color;
             -o-box-shadow: $top $left $blur $color;
            -ms-box-shadow: $top $left $blur $color;
           -moz-box-shadow: $top $left $blur $color;
        -webkit-box-shadow: $top $left $blur $color;
    }
}
 
@mixin border-radius($tl : 0, $tr : 0, $bl : 0, $br : 0) {
            border-radius: $tl $tr $bl $br;
         -o-border-radius: $tl $tr $bl $br; 
        -ms-border-radius: $tl $tr $bl $br;
       -moz-border-radius: $tl $tr $bl $br;
    -webkit-border-radius: $tl $tr $bl $br;
}
@mixin radius($radius : 0){
    @include border-radius($radius, $radius, $radius, $radius);
}

@mixin transition($for, $time, $type){
    -webkit-transition: $for $time $type;
       -moz-transition: $for $time $type;
        -ms-transition: $for $time $type;
         -o-transition: $for $time $type;
            transition: $for $time $type;
}

@mixin opacity($opacity){
          filter: $opacity * 100;
         opacity: $opacity;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="($opacity * 100)")";
    -moz-opacity: $opacity;
}

@mixin transform($value){
    transform: $value;
}
@mixin transform_rotate($deg){
            transform: rotate($deg);
        -ms-transform: rotate($deg);
    -webkit-transform: rotate($deg);
}

@mixin animation($animation, $time, $loop, $ease){
            animation: $animation $time $loop $ease;
         -o-animation: $animation $time $loop $ease;
        -ms-animation: $animation $time $loop $ease;
       -moz-animation: $animation $time $loop $ease;
    -webkit-animation: $animation $time $loop $ease;
}
@mixin _animation($name, $duration, $mode, $function, $count){
            animation-duration: $duration;
         -o-animation-duration: $duration;
        -ms-animation-duration: $duration;
       -moz-animation-duration: $duration;
    -webkit-animation-duration: $duration;

            animation-fill-mode: $mode;
         -o-animation-fill-mode: $mode;
        -ms-animation-fill-mode: $mode;
       -moz-animation-fill-mode: $mode;
    -webkit-animation-fill-mode: $mode;

            animation-timing-function: $function;
         -o-animation-timing-function: $function;
        -ms-animation-timing-function: $function;
       -moz-animation-timing-function: $function;
    -webkit-animation-timing-function: $function;

            animation-iteration-count: count;
         -o-animation-iteration-count: count;
        -ms-animation-iteration-count: count;
       -moz-animation-iteration-count: count;
    -webkit-animation-iteration-count: count;

            animation-name: $name;
         -o-animation-name: $name;
        -ms-animation-name: $name;
       -moz-animation-name: $name;
    -webkit-animation-name: $name;
}

@mixin background_clip($type){
            background-clip: $type;
       -moz-background-clip: $type;
    -webkit-background-clip: $type;
}
@mixin text_fill_color($color){
            text-fill-color: $color;
    -webkit-text-fill-color: $color;
}

@mixin placeholder{
    &::placeholder{
        @content;
    }
}
@mixin max-screen($res-first, $res-last: $res-first) {
    @if $res-first == $res-last {
        @media screen and (max-width: if(unitless($res-first), $res-first*$units, $res-first)) {
            @content;
        }
    } 
    @else if $res-first < $res-last {
        @media screen and (min-width: if(unitless($res-first), $res-first*$units, $res-first)) and (max-width: if(unitless($res-last), $res-last*$units, $res-last)) {
            @content;
        }
    }
}
@mixin min-screen($res){
    @media screen and (min-width: if(unitless($res), $res * 1px, $res)) {
        @content;
    }
}

@mixin filter($filter){
            filter: $filter; 
        -ms-filter: $filter;
       -moz-filter: $filter;
    -webkit-filter: $filter;
}

@mixin backlight($x, $y, $spread, $size, $colorA, $colorB, $duration){
    &:after {
        position: absolute;
        content: "";
        top: $y;
        left: $x;
        right: 0;
        z-index: -1;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        @include transform(scale($size));
        @include filter(blur($spread));
        background: linear-gradient(270deg, $colorA, $colorB);
        background-size: 200% 200%;
        animation: animateGlow $duration ease infinite;
    }
}