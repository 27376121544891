$font-light         :   300;
$font-regular       :   400;
$font-medium        :   500;
$font-semi-bold     :   600;
$font-bold          :   700;
$font-black         :   800;
$font-heavy         :   900;
$fontWeights        :   (
    'light'         :   $font-light,
    'regular'       :   $font-regular,
    'medium'        :   $font-medium,
    'semi-bold'     :   $font-semi-bold,
    'bold'          :   $font-bold,
    'black'         :   $font-black,
    'heavy'         :   $font-heavy,
);

$color-blue         :   #007bff !default;
$color-indigo       :   #6610f2 !default;
$color-purple       :   #6f42c1 !default;
$color-pink         :   #e83e8c !default;
$color-red          :   #dc3545 !default;
$color-orange       :   #fd7e14 !default;
$color-yellow       :   #ffc107 !default;
$color-green        :   #28a745 !default;
$color-teal         :   #20c997 !default;
$color-cyan         :   #17a2b8 !default;
$color-gray         :   #888;
$color-white        :   white;
$color-black        :   black;
$color-light-gray   :   #DDD;
$color-dark-gray    :   #222;
$color-dark-red     :   #3D0800;
$color-dark-green   :   #1E2600;
$color-dark-orange  :   rgb(40, 20, 0);
$color-dark-blue    :   #00244A;
$color-cookie       :   rgb(156, 98, 57);
$color-info         :   $color-dark-blue;
$color-warning      :   $color-dark-orange;
$color-error        :   $color-dark-red;
$color-success      :   $color-dark-green;
$colors             :   (
    'blue'          :   $color-blue,
    'indigo'        :   $color-indigo,
    'purple'        :   $color-purple,
    'pink'          :   $color-pink,
    'red'           :   $color-red,
    'orange'        :   $color-orange,
    'yellow'        :   $color-yellow,
    'green'         :   $color-green,
    'teal'          :   $color-teal,
    'cyan'          :   $color-cyan,
    'gray'          :   $color-gray,
    'white'         :   $color-white,
    'black'         :   $color-black,
    'light-gray'    :   $color-light-gray,
    'dark-gray'     :   $color-dark-gray,
    'dark-red'      :   $color-dark-red,
    'dark-green'    :   $color-dark-green,
    'dark-orange'   :   $color-dark-orange, 
    'dark-blue'     :   $color-dark-blue,
    'cookie'        :   $color-cookie,
    'info'          :   $color-info,
    'warning'       :   $color-warning,
    'error'         :   $color-error,
    'success'       :   $color-success,
);

$shadow-blue                :   0 0 5px 1px rgba(0, 123, 255, 0.2);
$shadow-indigo              :   0 0 5px 1px rgba(102, 16, 242, 0.2);
$shadow-purple              :   0 0 5px 1px rgba(111, 66, 193, 0.2);
$shadow-pink                :   0 0 5px 1px rgba(232, 62, 140, 0.2);
$shadow-red                 :   0 0 5px 1px rgba(220, 53, 69, 0.2);
$shadow-orange              :   0 0 5px 1px rgba(253, 126, 20, 0.2);
$shadow-yellow              :   0 0 5px 1px rgba(255, 193, 7, 0.2);
$shadow-green               :   0 0 5px 1px rgba(40, 167, 69, 0.2);
$shadow-teal                :   0 0 5px 1px rgba(32, 201, 151, 0.2);
$shadow-cyan                :   0 0 5px 1px rgba(23, 162, 184, 0.2);
$shadow-gray                :   0 0 3px 0 rgba(255, 255, 255, 0.2);
$shadow-alphadark           :   0 1px 0 rgba(0, 0, 0, 0.06), 0 1px 1px rgba(255, 255, 255, 0.04), 0 1px 0 rgba(0, 0, 0, 0.8);
$shadow-alphadark-v2        :   0 1px 0 rgba(255, 255, 255, 0.16) inset, 0 1px 1px rgba(0, 0, 0, 0.4);
$shadow-alphadark-v3        :   0 1px 0 rgba(255, 255, 255, 0.07), 0 -1px 1px rgba(255, 255, 255, 0.04);
$shadow-alphawhite          :   0 1px 0 rgba(255, 255, 255, 0.15);
$shadow-alphagray-inset     :   0 0 10px rgba(0,0,0,0.25), 0 0 5px rgba(255,255,255,0.25)inset;
$shadow-alphablack-inset    :   0 0 10px rgba(0, 0, 0, 0.5) inset;
$shadow-window              :   0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.5);
$shadow-big-black           :   0 0 20px black;
$shadow-big-up-black        :   0 -1px 20px black;
$shadows                    :   (
    'blue'                  :   $shadow-blue,
    'indigo'                :   $shadow-indigo,
    'purple'                :   $shadow-purple,
    'pink'                  :   $shadow-pink,
    'red'                   :   $shadow-red,
    'orange'                :   $shadow-orange,
    'yellow'                :   $shadow-yellow,
    'green'                 :   $shadow-green,
    'teal'                  :   $shadow-teal,
    'cyan'                  :   $shadow-cyan,
    'gray'                  :   $shadow-gray,
    'alphadark'             :   $shadow-alphadark,
    'alphadark-v2'          :   $shadow-alphadark-v2,
    'alphadark-v3'          :   $shadow-alphadark-v3,
    'alphawhite'            :   $shadow-alphawhite, 
    'alphagray-inset'       :   $shadow-alphagray-inset,
    'window'                :   $shadow-window,
    'big-black'             :   $shadow-big-black,
    'big-up-black'          :   $shadow-big-up-black,
);

$bg-blue                    :   #007bff !default;
$bg-indigo                  :   #6610f2 !default;
$bg-purple                  :   #6f42c1 !default;
$bg-pink                    :   #e83e8c !default;
$bg-red                     :   #dc3545 !default;
$bg-orange                  :   #fd7e14 !default;
$bg-yellow                  :   #ffc107 !default;
$bg-green                   :   #28a745 !default;
$bg-teal                    :   #20c997 !default;
$bg-cyan                    :   #17a2b8 !default;
$bg-gray                    :   #888;
$bg-white                   :   white;
$bg-black                   :   black;
$bg-light-gray              :   #DDD;
$bg-dark-gray               :   #222;
$bg-dark-red                :   #3D0800;
$bg-dark-green              :   #1E2600;
$bg-dark-orange             :   rgb(40, 20, 0);
$bg-dark-blue               :   #00244A;
$bg-cookie                  :   rgb(156, 98, 57);
$bg-dark-gray               :   #111;
$bg-alphablack              :   rgba(255, 255, 255, 0.02);
$bg-alphagray               :   rgba(255, 255, 255, 0.3);
$bg-alphared                :   rgba(255, 0, 0, 0.3);
$bg-alphagreen              :   rgba(0, 155, 0, 0.3);
$bg-alphaorange             :   rgba(255, 74, 0, 0.3);
$bg-alphablue               :   rgba(25, 125, 255, 0.3);
$bg-info                    :   $bg-alphablue;
$bg-warning                 :   $bg-alphaorange;
$bg-error                   :   $bg-alphared;
$bg-success                 :   $bg-alphagreen;
$backgrounds                :   (
    'blue'                  :   $bg-blue,
    'indigo'                :   $bg-indigo,
    'purple'                :   $bg-purple,
    'pink'                  :   $bg-pink,
    'red'                   :   $bg-red,
    'orange'                :   $bg-orange,
    'yellow'                :   $bg-yellow,
    'green'                 :   $bg-green,
    'teal'                  :   $bg-teal,
    'cyan'                  :   $bg-cyan,
    'gray'                  :   $bg-gray,
    'light-gray'            :   $bg-light-gray,
    'dark-gray'             :   $bg-dark-gray,
    'dark-red'              :   $bg-dark-red,
    'dark-green'            :   $bg-dark-green,
    'dark-orange'           :   $bg-dark-orange, 
    'dark-blue'             :   $bg-dark-blue,
    'cookie'                :   $bg-cookie,
    'alphablack'            :   $bg-alphablack,
    'alphagray'             :   $bg-alphagray,
    'alphared'              :   $bg-alphared,
    'alphagreen'            :   $bg-alphagreen,
    'alphaorange'           :   $bg-alphaorange,
    'alphablue'             :   $bg-alphablue,
    'info'                  :   $bg-alphablue,
    'warning'               :   $bg-alphaorange,
    'error'                 :   $bg-alphared,
    'success'               :   $bg-alphagreen,
);

$bg-granule                 :    url("./../images/backgrounds/granule.jpg") repeat;
$bg-dark                    :   black url('./../images/backgrounds/alpha.png') repeat-x top left;
$bg-dark-fixed              :   black url('./../images/backgrounds/alpha.png') repeat-x fixed;
$bg-middle-dark             :   #181818 url('./../images/backgrounds/alpha.png') repeat-x 0 -50px;
$bg-triangle-dark           :   #111 url('./../images/backgrounds/alpha-triangle.png') repeat;
$bg-triangle                :   url('./../images/backgrounds/alpha-triangle.png') repeat;
$bg-triangle-light          :   white url('./../images/backgrounds/alpha-triangle.png') repeat;
$bg-iphone-light            :   url('./../images/backgrounds/iphone-white.jpg') repeat;
$bg-light                   :   white url('./../images/backgrounds/alpha.png') repeat-x top left,;
$bg-wave-light              :   white url('./../images/backgrounds/wave.png') repeat-x;
$backgrounds_image          :   (
    'granule'               :   $bg-granule,
    'dark'                  :   $bg-dark,
    'dark-fixed'            :   $bg-dark-fixed,
    'middle-dark'           :   $bg-middle-dark,
    'triangle-dark'         :   $bg-triangle-dark,
    'triangle'              :   $bg-triangle,
    'trianlge-light'        :   $bg-triangle-light,
    'iphone-light'          :   $bg-iphone-light,
    'light'                 :   $bg-light, 
    'wave-light'            :   $bg-wave-light,
);

$fonts-size                 :   (
    7                       :   0.7,
    8                       :   0.8,
    9                       :   0.9,
    10                      :   1,
    11                      :   1.1,
    12                      :   1.2,
    13                      :   1.3,
    14                      :   1.4,
    15                      :   1.5,
    16                      :   1.6,
    17                      :   1.7,
    18                      :   1.8,
    19                      :   1.9,
    20                      :   2,
);
